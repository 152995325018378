import { assertPlatform, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';

import { MasterService } from '../../../_services/master.service';

import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';

import { TokenStorageService } from 'src/app/_services/token-storage.service';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';

import { Result, BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';


export interface Tutorial {
  id?: any;
  code?: string,
  pengirim?: string,
  status_kurir?: string,
  status_pickup?: string,
  status_direct?: string,
  status_agen?: string,
  tgl_kirim?: string,
  // id_kurir?: string,
  name_kurir?: string,
  no_resi?: string,
  id_shippers?: string,
  name_pengirim?: string,
  id_kurirs?: string,
  status_paket?: string,
  tgl_terima?: string,
  berat_paket?: string,
  isi_paket?: string,
  images?: string,
  fileimage?: string,
  fileimage1?: string,
  fileimage2?: string,
  fileimage3?: string,
  status_dikurir?: string,
  description?: string,
  nameshippers?: string,
  id_city?: any;
  type_id?: any;
  subtype_id?: any;
  status_inbound?: any;
  id_partner?: any;
  status_idpartner?: any;
  namakurirs?: any;
  cleansings_id?: any;
  full_name?: any;
  action?: string
}


@Component({
  selector: 'app-add-inboundadd',
  templateUrl: './add-inboundadd.component.html',
  styleUrls: ['./add-inboundadd.component.css']
})
export class AddinboundaddComponent implements OnInit {

 inboundImg = 'https://apis.nissaexpress.com/uploads/images/inbound/';
//  inboundImg = 'http://localhost:39909/uploads/images/inbound/';
 isLoading$ = false;

 image: File | undefined;
 resData: any;
 selectedFile : any;
 selectedFile1 : any;
 selectedFile2 : any;
 selectedFile3 : any;
//  selectedFile = null;
// SELECT TYPE
  typeItems: any[] = [];
  selectedTypeItem: any | undefined;

  subtypeItems: any[] = [];
  selectedSubTypeItem: any | undefined;

  // tutorials: Tutorial[] = [];
  // currentTutorial: Tutorial = {};
  // currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  // pageSizes = [10, 25, 50, 100, 500, 1000, 2000, "ALL"];
  isCreated:boolean = true;

  code: any;
  tgl_kirim: any;
  name_kurir: any;
  no_resi: any;
  id_shippers: any;
  name_pengirim: any;
  id_kurirs: any;
  status_paket: any;
  tgl_terima: any;
  berat_paket: any;
  isi_paket: any;
  status_kurir: any;
  pengirim: any;
  status_pickup: any;
  status_direct: any;
  status_agen: any;
  description: any;
  type_id: any;
  subtype_id: any;
  images: any;
  imagess: any;
  imagess1: any;
  imagess2: any;
  imagess3: any;
  fileimage: any;
  fileimage1: any;
  fileimage2: any;
  fileimage3: any;
  nameshippers: any;
  namakurirs: any;
  id: any;

  // countries: any[] = [];
  // selectedShipperPhoneNumberCountry: any = {};

  action:any = '';
  phonecode: any;

  vcombos:any = [];
  vcomboskurir:any = [];
  myFiles: any = [];

  ngOnInit(): void {
    this.retrieveCombodatakurirs();
    this.retrieveCombodatatypeItems();
    // this.fetchDropdownValues();
    this.getUserData();
  }

  // START SCAN
   //scan
   availableDevices!: MediaDeviceInfo[];
   currentDevice!: MediaDeviceInfo;

   formatsEnabled: BarcodeFormat[] = [
     BarcodeFormat.CODE_128,
     BarcodeFormat.DATA_MATRIX,
     BarcodeFormat.EAN_13,
     BarcodeFormat.QR_CODE,
   ];

   hasDevices!: boolean;
   hasPermission!: boolean;

   qrResultString!: string;

   torchEnabled = false;
   torchAvailable$ = new BehaviorSubject<boolean>(false);
   tryHarder = false;
  // END SCAN

  submitted!:boolean;
  // event: any;
  angForm: FormGroup | any;
  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService,
    private fb: FormBuilder,
    private tokenStorage: TokenStorageService,
    private router: Router
    ) {
      this.createForm();
    }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  isCustomer:boolean = false;
  isAgen:boolean = false;
  isFinance:boolean = false;
  isCleansings:boolean = false;
  isInbound:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res)
		  this.usersData.role.forEach((e:any) => {
			this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
      if(this.rolesString.includes('Agen')) {
        this.isAgen = true;
      }
      if(this.rolesString.includes('Finance')) {
        this.isFinance = true;
      }
      if(this.rolesString.includes('Cleansings')) {
        this.isCleansings = true;
      }
      if(this.rolesString.includes('Inbound')) {
        this.isInbound = true;
      }
			// console.log(this.isAdmin)
    })
  }

  createForm() {
    this.angForm = this.fb.group({
      //  code: ['', [Validators.required ]],
       tgl_kirim: [null],
       name_kurir: [null],
       no_resi: [null],
       pengirim: [null],
       name_pengirim: [null],
       status_kurir: [],
       status_pickup: [],
       status_direct: [],
       status_agen: [],
       id_shippers: [''],
       id_kurirs: [''],
       status_paket: [''],
       tgl_terima: [''],
       berat_paket: [''],
      //  isi_paket: ['', [Validators.required ]],
       description: [''],
       type_id: [''],
       subtype_id: [''],
      //  template_option: ['', [Validators.required] ]
    });

    // console.log('masuk')

  }

  // START SCAN
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  // namaitems:any;
  // namasubitems:any;
  // onCodeResult(resultString: string) {
  //   this.qrResultString = resultString;
  //   // console.log(resultString == '', 'resultString')
  //   this.no_resi = resultString;
  //   this.tutorialService
  //     .get(`inbound/getresi/${this.no_resi}`)
  //     .subscribe((data: any) => {
  //       this.code = data.data.code;
  //       this.id_shippers = data.data.id_shippers;
  //       this.nameshippers = data.data.nameshippers;
  //       this.id_kurirs = data.data.id_kurirs;
  //       this.namakurirs = data.data.namakurirs;
  //       this.tgl_kirim = data.data.tgl_kirim;
  //       this.id = data.data.id;
  //       this.status_paket = data.data.status_paket == 'N' ? "Belum di Terima" : "Terima";
  //       this.namaitems = data.data.namaitems;
  //       this.namasubitems = data.data.namasubitems;
  //       this.berat_paket = data.data.berat_paket;
  //       this.tgl_terima = data.data.tgl_terima;
  //       this.description = data.data.description;
  //     },(error: any) => {
  //       // console.log(error, 'error1');
  //       this.presentToast(error.error.errors, 'error');
  //     },
  //       () => {
  //       console.log("Complete");
  //     });

    
  // }

  onCodeResult1(resultString: string) {
    this.qrResultString = resultString;
    // console.log(this.qrResultString, 'qrResultString')
    this.no_resi = resultString;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  // END SCAN

  open(content:any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';

    this.id = null;
    this.pengirim = null;
    this.tgl_kirim = null;
    this.name_kurir = null;
    this.no_resi = null;
    this.name_pengirim = '';
    this.id_shippers = '';
    this.id_kurirs = '';
    this.type_id = {};
    this.subtype_id = {};
    this.status_paket = null;
    this.tgl_terima = null;
    this.berat_paket = null;
    this.isi_paket = null;
    this.status_kurir = null;
    this.status_pickup = null;
    this.status_direct = null;
    this.status_agen = null;
    this.description = null;
    this.type_id = null;
    this.selectedTypeItem = {};
    this.selectedSubTypeItem = {};
    this.selectedFile = '';
    this.selectedFile1 = '';
    this.selectedFile2 = '';
    this.selectedFile3 = '';

    //scan
    this.nameshippers = '';
    this.namakurirs = '';
    // this.namaitems = '';

		this.modalService.open(content, { size: 'lg' });
	}

  // START
  onFileSelected(event:any) {
    this.selectedFile = event.target.files[0];
  }

  onFileSelected1(event:any) {
    this.selectedFile1 = event.target.files[0];
  }
  onFileSelected2(event:any) {
    this.selectedFile2 = event.target.files[0];
  }
  onFileSelected3(event:any) {
    this.selectedFile3 = event.target.files[0];
  }


  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveCombodatakurirs(): void {
    const params = {};

    this.tutorialService.getAlls('kurirs/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.vcomboskurir = data;
        console.log(this.vcomboskurir, 'this.vcomboskurir')
      },
      error => {
        this.presentToast(error.error.errors, 'error');
      });
  }

  retrieveCombodatatypeItems(): void {
    const params = {};

    this.tutorialService.getAlls('typeitems/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.typeItems = data;
      },
      error => {
        this.presentToast(error.error.errors, 'error');
        // console.log(error);
      });
  }

  onAutoCompleteSelected(event: any, tipe: string) {
    const val = {
      value: event,
    };
    this.onDropdownChange(val, tipe);
  }

  onDropdownChange(event: any, tipe: string) {
    // console.log(event.value)
    if(tipe === 'type_id') {
      this.fetchSubItem(event.value.id);
      this.selectedTypeItem = event.value;
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = event.value;
    }
  }

  onDropdownClear(tipe: string) {
    if(tipe === 'type_id') {
      this.selectedTypeItem = {};
    } else if(tipe === 'subtype_id') {
      this.selectedSubTypeItem = {};
    }
  }

  fetchSubItem(event: any) {
    let params: any = {};
    params.type_id = event

    this.tutorialService.getAlls('typeitemsubs/combodata', params)
    .subscribe(
      response => {
        const { data } = response;
        this.subtypeItems = data;
        let subType = this.subtypeItems.find((e:any) => e.id == this.subtype_id);
        // console.log(subType, 'subType i')
        this.selectedSubTypeItem = subType;
      },
      error => {
        // console.log(error);
        this.presentToast(error.error.errors, 'error');
      });
  }

  fadd_role: Tutorial = {
    tgl_kirim: '',
    pengirim: '',
    name_kurir: '',
    no_resi: '',
    id_shippers: '',
    name_pengirim: '',
    id_kurirs: '',
    status_paket: '',
    tgl_terima: '',
    berat_paket: '',
    isi_paket: '',
    status_kurir: '',
    status_pickup: '',
    status_direct: '',
    status_agen: '',
    description: '',
    type_id: '',
    subtype_id: ''
  };
  
  saveBySCAN(): void {

      const formData = new FormData();
      formData.append('pengirim', this.pengirim);
      formData.append('tgl_kirim', this.tgl_kirim);
      formData.append('name_kurir', this.name_kurir);
      formData.append('no_resi', this.no_resi);
      formData.append('id_shippers', this.id_shippers);
      formData.append('name_pengirim', this.name_pengirim);
      formData.append('id_kurirs', this.id_kurirs);
      formData.append('status_paket', this.status_paket);
      formData.append('tgl_terima', this.tgl_terima);
      formData.append('berat_paket', this.berat_paket);
      formData.append('isi_paket', this.isi_paket);
      formData.append('status_agen', this.status_agen);
      formData.append('description', this.description);
      formData.append('type_id', this.selectedTypeItem == undefined ? '' : this.selectedTypeItem.id);
      formData.append('subtype_id', this.selectedSubTypeItem == undefined ? '' : this.selectedSubTypeItem.id);
      formData.append('fileimage', this.selectedFile);
      formData.append('fileimage1', this.selectedFile1);
      formData.append('fileimage2', this.selectedFile2);
      formData.append('fileimage3', this.selectedFile3);

      this.isLoading$ = true;
  
      this.tutorialService.postData('inbound/createscanV2', formData)
        .pipe(
          finalize(() => this.isLoading$ = false)
        )
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.router.navigate(['/admin/app-tr-inbound']);
            // this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });

  }

  presentToast(msg:any, status:any) {
    // console.log(msg)
    if(status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  // openData(tutorial:any, action:any) {
  //   console.log(tutorial,'tutorial')
  //   const param: NavigationExtras = {
  //     queryParams: {
  //       info: JSON.stringify(tutorial),
  //       action: JSON.stringify(action)
  //     }
  //   }
  //   this.router.navigate(['/admin/form-cleansing'], param);
  // }

   reloadPage(): void {
    window.location.reload();
  }

}
