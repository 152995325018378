import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { MasterService } from 'src/app/_services/master.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

    public currentHref: string = "";


  constructor(
    location: Location,
    public tokenService: TokenStorageService,
    private masterService: MasterService,
    private tokenStorage: TokenStorageService,
    router: Router

  ) {
    router.events.subscribe((val) => {
      if(location.path() != ''){
        this.currentHref = location.path();
      } else {
        this.currentHref = 'Home'
      }
    });
  }



  ngOnInit(): void {
    // this.getToken();
    this.getUserData();
  }

  usersData:any = {};
	rolesString:any = '';
  isAdmin:boolean = false;
  isSuperadmin:boolean = false;
  // isCustomer:boolean = false;
  isAgen:boolean = false;
  isFinance:boolean = false;
  isCleansings:boolean = false;
  isInbound:boolean = false;
  isDash:boolean = false;
  isManagement:boolean = false;
  getUserData() {
    this.tokenStorage.getUsers().then(async res => {
      this.usersData = res;
      // console.log(res,'res')
		  this.usersData.role.forEach((e:any) => {
			  this.rolesString = this.rolesString+e.name+', ';
		  });
			// console.log(this.rolesString)
      if(this.rolesString.includes('Admin')) {
        this.isAdmin = true;
      }
      if(this.rolesString.includes('Superadmin')) {
        this.isSuperadmin = true;
      }
      if(this.rolesString.includes('Agen')) {
        this.isAgen = true;
      }
      if(this.rolesString.includes('Finance')) {
        this.isFinance = true;
      }
      if(this.rolesString.includes('Cleansings')) {
        this.isCleansings = true;
      }
      if(this.rolesString.includes('Inbound')) {
        this.isInbound = true;
      }
      if(this.rolesString.includes('Dash')) {
        this.isDash = true;
      }
      if(this.rolesString.includes('Management')) {
        this.isManagement = true;
      }
			// console.log(this.isAdmin)
    })
  }

  // async getToken() {
  //   let user = await this.tokenService.getUser();
  //   // console.log(user)
  //   // this.getAccessMenu(user);
  // }

  // mstArray2:any = [];
  // getAccessMenu(user:any) {
  //   //api/rolemenuaccess/detail/
  //   for(var i=0; i<user.role.length; i++) {
  //     this.masterService.getData('rolemenuaccess/detail/'+user.role[i].id).subscribe(res => {
  //       console.log(res.data.menu)
  //       this.mstArray2 = res.data.menu;
  //       this.mstArray2 = this.findDuplicates(this.mstArray2, res.data.menu);

  //       console.log(this.mstArray2)
  //       this.parseMenu(res);
  //     })
  //   }
  // }

  // accessMenu:any = {};
  // parseMenu(res:any) {
  //   for(var i=0; i<res.data.menu.length; i++) {
  //     this.accessMenu['/'+res.data.menu[i].url] = true;
  //   }
  //   console.log(this.accessMenu)
  // }

  // findDuplicates(array1:any, array2:any) {
  //   const result = array2.map((a:any) => {
  //     let match = array1.find((b:any) => b.url == a.url);
  //     return {
  //       icon : a.icon,
  //       is_main_menu : a.is_main_menu,
  //       title: a.title,
  //       url : match.url,
  //   }
  //   });
  //   return result;
  // };


  toggleIcon: boolean = true;

    toggleLoveIcon() {
        this.toggleIcon = !this.toggleIcon;
    }

    dashboardArray = [
         '/admin',
         '/admin/index',
         '/admin/index-2',
         '/admin/index-3',
         '/admin/index-4',
         '/admin/coin-details',
         '/admin/my-wallets',
         '/admin/transactions',
         '/admin/portofolio',
         '/admin/market-capital',
	  ];

    countrydArray = [
      '/admin/app-mst-country',
    ];

    citydArray = [
      '/admin/app-mst-city',
    ];

    flightsdArray = [
      '/admin/app-mst-flights',
    ];

    trackingsdArray = [
      '/admin/app-mst-tracking',
      '/admin/app-mst-trackinglokal',
    ];

    consigneedArray = [
      '/admin/app-mst-consignee',
    ];

    apsArray = [
        //  '/admin/app-profile',
         '/admin/post-details',
         '/admin/email-compose',
         '/admin/email-inbox',
         '/admin/email-read',
         '/admin/app-calender',
         '/admin/ecom-product-grid',
         '/admin/ecom-product-list',
         '/admin/ecom-product-detail',
         '/admin/ecom-product-order',
         '/admin/ecom-checkout',
         '/admin/ecom-invoice',
         '/admin/ecom-customers',

	];

    chartsArray = [
         '/admin/chart-chartjs',
         '/admin/chart-apex',
         '/admin/apex-line',
         '/admin/apex-area',
         '/admin/apex-column',
         '/admin/apex-bar',
         '/admin/apex-mixed',
         '/admin/apex-timeline',
         '/admin/apex-candlestick',
         '/admin/apex-pie',
         '/admin/apex-radar',
         '/admin/apex-radialbar',
         '/admin/apex-polar-area',
         '/admin/apex-bubble',
         '/admin/apex-scatter',
         '/admin/apex-heatmap',
         '/admin/apex-treemap',
         '/admin/apex-sparklines',
	];

    bootstrapArray = [
         '/admin/ui-accordion',
         '/admin/ui-alert',
         '/admin/ui-badge',
         '/admin/ui-button',
         '/admin/ui-datepicker',
         '/admin/ui-modal',
         '/admin/ui-button-group',
         '/admin/ui-list-group',
         '/admin/ui-media-object',
         '/admin/ui-card',
         '/admin/ui-carousel',
         '/admin/ui-dropdown',
         '/admin/ui-popover',
         '/admin/ui-progressbar',
         '/admin/ui-nav',
         '/admin/ui-rating',
         '/admin/ui-typography',
         '/admin/ui-table',
         '/admin/ui-pagination',
         '/admin/ui-timepicker',
         '/admin/ui-toast',
         '/admin/ui-tooltip',
         '/admin/ui-typeahead',
         '/admin/ui-grid',
	];

    materialArray = [
         '/admin/mat-autocomplete',
         '/admin/mat-badge',
         '/admin/mat-bottom-sheet',
         '/admin/mat-button',
         '/admin/mat-button-toggle',
         '/admin/mat-card',
         '/admin/mat-checkbox',
         '/admin/mat-chips',
         '/admin/mat-datepicker',
         '/admin/mat-dialog',
         '/admin/mat-divider',
         '/admin/mat-expansion',
         '/admin/mat-form-field',
         '/admin/mat-grid-list',
         '/admin/mat-icon',
         '/admin/mat-input',
         '/admin/mat-list',
         '/admin/mat-menu',
         '/admin/mat-paginator',
         '/admin/mat-progress-bar',
         '/admin/mat-progress-spinner',
         '/admin/mat-radio',
         '/admin/mat-ripple',
         '/admin/mat-select',
         '/admin/mat-sidenav',
         '/admin/mat-slide-toggle',
         '/admin/mat-slider',
         '/admin/mat-snack-bar',
         '/admin/mat-sort',
         '/admin/mat-stepper',
         '/admin/mat-table',
         '/admin/mat-tab',
         '/admin/mat-tooltip',
         '/admin/mat-tree',
         '/admin/mat-toolbar',
	];

    pluginsArray = [
         '/admin/uc-nestable',
         '/admin/uc-lightgallery',
	];

    formsArray = [
         '/admin/form-element',
         '/admin/form-validate',
	];

  mstArray = [
    '/admin/app-mst-role',
    '/admin/app-mst-rolesdetail',
    '/admin/app-mst-user',
    '/admin/app-profile',
    '/admin/app-mst-menu',
    '/admin/app-mst-typeitems',
    '/admin/app-mst-subtypeitem',
    '/admin/app-mst-shippers',
    '/admin/app-mst-vendors',
    '/admin/app-mst-vendorsresi',
    // '/admin/app-mst-consignee',
    // '/admin/app-mst-tracking',
    '/admin/app-mst-contact-us',
    '/admin/app-mst-bank',
    '/admin/app-mst-rekening-bank',
    '/admin/app-mst-kurir',
    // '/admin/app-mst-sliders',
    // '/admin/app-mst-news',
    // '/admin/app-mst-daftarharga',
    '/admin/app-mst-kategoris',
    '/admin/app-mst-products',
    '/admin/app-mst-store-seven',
  ];

  mstArrayweb = [
    // '/admin/app-mst-role',
    // '/admin/app-mst-rolesdetail',
    // '/admin/app-mst-user',
    // '/admin/app-profile',
    // '/admin/app-mst-menu',
    // '/admin/app-mst-typeitems',
    // '/admin/app-mst-subtypeitem',
    // '/admin/app-mst-shippers',
    // '/admin/app-mst-vendors',
    // '/admin/app-mst-vendorsresi',
    // '/admin/app-mst-consignee',
    // '/admin/app-mst-tracking',
    // '/admin/app-mst-contact-us',
    // '/admin/app-mst-bank',
    // '/admin/app-mst-rekening-bank',
    // '/admin/app-mst-kurir',
    '/admin/app-mst-sliders',
    '/admin/app-mst-news',
    '/admin/app-mst-daftarharga',
    // '/admin/app-mst-kategoris',
    // '/admin/app-mst-products',
  ];

  trsArray = [
    '/admin/app-add-inboundadd',
    '/admin/app-tr-inboundproses',
    '/admin/app-tr-inbound',
    '/admin/app-tr-cleansings',
    '/admin/app-tr-cleansingscomplete',
    '/admin/app-tr-manifest',
    '/admin/app-tr-manifest-bag',
    '/admin/app-tr-manifest-bag-detail',
    '/admin/app-tr-tagihan',
    '/admin/app-tr-tagihanpaid',
  ];

  rpsArray = [
    '/admin/app-rp-inbound',
    '/admin/app-rp-cleansings',
    '/admin/app-rp-manifest',
    // '/admin/app-rp-manifest-bag',
    // '/admin/app-rp-manifest-bag-detail',
    '/admin/app-rp-tagihan',
    '/admin/app-rp-tagihanshipper',
    '/admin/app-rp-pembayaran',
  ];

}
