import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/bootstrap/toast/toast-global/toast-service';
import { MasterService } from '../../../_services/master.service';

export interface Tutorial {
  id?: any;
  store_number?: any;
  store_name?: any;
  store_city?: any;
  store_address?: any;
  store_address2?: any;
  action?: string;
}

@Component({
  selector: 'app-mst-store-seven',
  templateUrl: './mst-store-seven.component.html',
  styleUrls: ['./mst-store-seven.component.css']
})
export class MstStoresevenComponent implements OnInit {

  tutorials: Tutorial[] = [];
  currentTutorial: Tutorial = {};
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100];
  isCreated: boolean = true;

  vcombo: any = [];
  store_number: any;
  store_name: any;
  store_city: any;
  store_address: any;
  store_address2: any;

  constructor(
    private modalService: NgbModal,
    private tutorialService: MasterService,
    public toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.retrieveTutorials();
  }

  open(content: any) {
    this.isCreated = true;
    this.fadd_role.action = 'create';
    // this.id = null;
    this.store_number = null;
    this.store_name = null;
    this.store_city = null;
    this.store_address = null;
    this.store_address2 = null;
    this.modalService.open(content);
  }

  getRequestParams(searchTitle: string, page: number, pageSize: number): any {
    let params: any = {};

    if (searchTitle) {
      params[`title`] = searchTitle;
    }

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }

  retrieveTutorials(): void {
    const params = this.getRequestParams(this.title, this.page, this.pageSize);

    this.tutorialService.getAlls('stores/view', params)
      .subscribe(
        response => {
          const { data, totalItems } = response;
          this.tutorials = data;
          this.count = totalItems;
          // console.log(response);
        },
        error => {
          this.presentToast(error.error.errors, 'error');
          // console.log(error);
        });
  }

  

  handlePageChange(event: number): void {
    this.page = event;
    this.retrieveTutorials();
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.retrieveTutorials();
  }

  refreshList(): void {
    this.retrieveTutorials();
    this.currentTutorial = {};
    this.currentIndex = -1;
  }

  setActiveTutorial(tutorial: Tutorial, index: number): void {
    this.currentTutorial = tutorial;
    this.currentIndex = index;
  }

  searchTitle(): void {
    this.page = 1;
    this.retrieveTutorials();
  }

  fadd_role: Tutorial = {
    store_number: '',
    store_name: '',
    store_city: '',
    store_address: '',
    store_address2: '',
  };

  submitted!: boolean;
  saveStores(): void {
    const data = {
      store_number: this.fadd_role.store_number,
      store_name: this.fadd_role.store_name,
      store_city: this.fadd_role.store_city,
      store_address: this.fadd_role.store_address,
      store_address2: this.fadd_role.store_address2
    };
    // console.log(data, 'data');

    if (this.isCreated) {
      this.tutorialService.postData('stores/create', data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    } else {
      this.tutorialService.postData('stores/update/' + this.fadd_role.id, data)
        .subscribe(
          async response => {
            // console.log(response);
            this.fadd_role = {};
            this.presentToast(response.message, 'success');
            this.submitted = true;
            this.modalService.dismissAll();
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  editUser(sendMessageModal: any, tutorial: any, action: any) {
    this.isCreated = false;
    this.modalService.open(sendMessageModal);
    this.fadd_role.id = tutorial.id;
    this.fadd_role.store_number = tutorial.store_number;
    this.fadd_role.store_name = tutorial.store_name;
    this.fadd_role.store_city = tutorial.store_city;
    this.fadd_role.store_address = tutorial.store_address;
    this.fadd_role.store_address2 = tutorial.store_address2;
    this.fadd_role.action = action;
  }

  deleteVendors(data: any) {
    var conf = confirm('Anda yakin akan menghapus?');
    if (conf) {
      this.tutorialService.delete(`stores/delete/${data.id}`)
        .subscribe(
          async response => {
            // console.log(response);
            this.presentToast(response.message, 'success');
            this.retrieveTutorials();
          },
          async error => {
            // console.log(error);
            this.presentToast(error.error.errors, 'error');
          });
    }
  }

  presentToast(msg: any, status: any) {
    // console.log(msg)
    if (status == 'success') {
      this.toastService.show(msg, { classname: 'bg-success text-white', delay: 10000 });
    } else {
      this.toastService.show(msg, { classname: 'bg-danger text-white', delay: 10000 });
    }
  }

  reloadPage(): void {
    window.location.reload();
  }

}
